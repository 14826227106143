import React, {PureComponent} from 'react';
import PlaylistIcon from "../menu/Menu";
import PlaylistTitle from "./PlaylistTitle";
import './PlaylistName.css';

class PlaylistName extends PureComponent {
    render() {
        const titles = this.props.titles;
        return (
            <div>
                <PlaylistIcon/>
                <div className="playlistTitle__type recentlyPlayed">
                    RECENTLY PLAYED
                </div>
                {titles.map((title, index) => (<PlaylistTitle key={index} title={title} id={index}/>))}
            </div>
        );
    }
}

PlaylistName.propTypes = {};

export default PlaylistName;