import React, {PureComponent} from 'react';
import PlaylistName from "../playlistname/PlaylistName";
import "./Sidebar.css"
import Logo from "../logo/Logo";
import {connect} from 'react-redux';
import {getPlaylistsTitles} from "../../action/getPlaylistsTitles";

class Sidebar extends PureComponent {
    componentWillMount() {
        this.props.getPlaylistsTitles();
    }

    render() {
        return (
            <div className="sidebar">
                <Logo/>
                <PlaylistName titles={this.props.playlistTitles}/>
            </div>
        );
    }
}

Sidebar.propTypes = {};

const mapStateToProps = state => ({
    playlistTitles: state.playlist.titles
});

export default connect(mapStateToProps, {getPlaylistsTitles})(Sidebar);