import { GET_PLAYLISTS_TITLES } from './type'
import Token from './util';

export const getPlaylistsTitles = request => (dispatch) => {
    new Token().getValue()
        .then((token) => {
            fetch('https://api.spotify.com/v1/users/1121507182/playlists', {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            }).then(response => response.json())
                .then((data) => {
                    dispatch({
                        type: GET_PLAYLISTS_TITLES,
                        data: data.items.map(value => ({ "name": value['name'], "id": value['id'], image:value['images'][0]['url'] })),
                    });
                })
                .catch([{}]);
        })
};
