import React, {PureComponent} from 'react';
import Sidebar from "./component/sidebar/Sidebar";
import Main from "./component/main/Main";
import "./App.css";
import {Provider} from 'react-redux';
import store from "./istore";
import {BrowserRouter} from "react-router-dom";

class App extends PureComponent {
    render() {
        return (
            <Provider store={store}>
                <BrowserRouter>
                    <div className="app">
                        <Sidebar/>
                        <Main/>
                    </div>
                </BrowserRouter>

            </Provider>
        );
    }
}

App.propTypes = {};

export default App;