import Cookies from 'js-cookie';

window.Cookies = Cookies;

function Token() {
    function getValue() {
        if( Cookies.get('token')) {
            return new Promise((resolve => resolve(Cookies.get('token'))))
        } else {
            return read();
        }

        function read() {
            return fetch('https://inadram-eval-prod.apigee.net/spotify-mashup')
                .then(response => response.json()).then((data) => {
                    Cookies.set('token', data['token'], {
                        expires: 1 / 48
                    }, { secure: true });
                    return data['token'];
                });
        }

    }

    return { getValue }
}

export default Token;