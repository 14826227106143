import { GET_PLAYLIST } from './type'
import Token from './util';

export const getPlaylistDetail = (id) => dispatch => {
    new Token().getValue()
        .then((token) => {
            fetch('https://api.spotify.com/v1/playlists/'.concat(id).concat('?fields=description%2Cimages%2Ctracks(href%2Citems(track(id%2Cname%2Cartists)))'), {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            }).then(response => response.json())
                .then((data) => {
                    dispatch({
                        type: GET_PLAYLIST,
                        data: data,
                    });
                })
                .catch([{}]);
        })
};