import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { getPlaylistsTitles } from '../../action/getPlaylistsTitles';
import './Library.css';
import { withRouter } from 'react-router-dom';

class Library extends PureComponent {
    componentWillMount() {
        this.props.getPlaylistsTitles();
    }

    constructor(props){
        super(props);
        this.props = props;
        this.showPlaylist = this.showPlaylist.bind(this);
    }

    showPlaylist(event) {
        this.props.history.push('/album/' + event.currentTarget.id);
    }

    render() {
        return (
            <div>
                <div className="library__header">
                    <ul className="library_menu">
                        <li className="library_menu--item">PLAYLISTS</li>
                    </ul>
                    <div>
                        <a href="https://github.com/inadram/" target="_blank" rel="noopener noreferrer"><button className="button">Github</button></a>
                        <a href="https://www.linkedin.com/in/inadram/" target="_blank" rel="noopener noreferrer"><button className="button">LinkedIn</button></a>
                        <a href="https://blog.inadram.com/" target="_blank" rel="noopener noreferrer"><button className="button">Blog</button></a>
                    </div>
                </div>
                <div className="libraryList">
                    {this.props.playlistNames.map(value => (
                        <div key={value.id} className="library__name" id={value.id} onClick={this.showPlaylist}>
                            <img className="library__cover" alt="cover" key={"img_" + value.id} src={value.image} />
                            <div key={"name_" + value.id}>{value.name}</div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

Library.propTypes = {};

const mapStateToProps = state => ({
    playlistNames: state.playlist.titles
});

export default connect(mapStateToProps, { getPlaylistsTitles })(withRouter(Library));
