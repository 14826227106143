import React, {Component} from 'react';
import PlaylistDetail from "../playlistdetail/PlaylistDetail"
import './Main.css'
import {Route, Switch} from "react-router-dom";
import PlaylistName from '../library/Library';

class Main extends Component {
    render() {
        return (
            <div className="main">
                <Switch>
                    <Route path="/album/:id" component={PlaylistDetail}/>}/>
                    <Route component={PlaylistName}/>}/>
                </Switch>
            </div>
        );
    }
}

export default Main;
