import React from 'react';
import './Menu.css';
import { withRouter } from 'react-router-dom';

const Menu = (props) => {
    function showPlaylistNames() {
        props.history.push('/collection/playlists/');
    }

    return (
        <div className="menu pointer" onClick={showPlaylistNames} tabIndex="2" >
            <div className="menu__item">
                <div className="menu__icon">

                </div>
                <div className="menu__description">
                    Your Library
                </div>
            </div>
        </div>
    );
};

Menu.propTypes = {};

export default withRouter(Menu);