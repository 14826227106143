import React, { Component } from 'react';
import './PlaylistDetail.css';
import { connect } from "react-redux";
import { getPlaylistDetail } from '../../action/getPlaylistDetail';

class PlaylistDetail extends Component {

    componentWillMount() {
        this.getPlaylist();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.getPlaylist();
        }
    }

    getPlaylist() {
        this.id = this.props.match.params.id;
        this.props.getPlaylistDetail(this.id);
    }

    render() {
        return <div className="playlistDetail">
            <div className="album">
                <img className="album__cover" src={this.props.playlistDetails.images[0].url}
                     alt={this.props.playlistDetails.description} />
                <div className="album_details">
                    <div>{this.props.playlistDetails.description}</div>
                    <div className="album__count">{this.props.playlistDetails.tracks.items.length} SONGS</div>
                </div>
            </div>
            <div className="tracks">
                {this.props.playlistDetails.tracks.items.map((item, index) => (
                    <div key={index} className="track pointer" tabIndex={index}>
                        <div className="track__icon" />
                        <div className="track__item">
                            <div className="track__name" key={item.track.id}>{item.track.name}</div>
                            <div className="track__artist"
                                 key={"artist" + item.track.id}>{item.track.artists.map(artist => artist.name).join(', ')}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>;
    }
}

PlaylistDetail.propTypes = {};

function mapStateToProps(state) {
    return {
        playlistDetails: state.playlist.details,
    }
}

export default connect(mapStateToProps, { getPlaylistDetail: getPlaylistDetail })(PlaylistDetail);