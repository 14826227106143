import { GET_PLAYLIST, GET_PLAYLISTS_TITLES } from '../action/type';

const initialState = {
    titles: [],
    details: { images: [{},{},{}], tracks: { items: [{ track: {artists:[{}]} }] } }
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PLAYLISTS_TITLES:
            return {
                ...state,
                titles: action.data
            };
        case GET_PLAYLIST:
            return {
                ...state,
                details: action.data
            };
        default:
            return state;
    }
}