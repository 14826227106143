import React from 'react';
import { withRouter } from "react-router-dom";
import './PlaylistTitle.css'

const PlaylistTitle = (props) => {
    function showPlaylist(event) {
        props.history.push('/album/' + event.currentTarget.id);
    }

    const title = props.title;
    const id = props.id;
    return (
        <div className="playlistTitle" tabIndex={id} key={title.id}>
            <div className="playlistTitle__item" id={title.id} onClick={showPlaylist}>
                <div className="playlistTitle__name pointer">
                    {title.name}
                </div>
                <div className="playlistTitle__type">
                    PLAYLIST
                </div>
            </div>
        </div>
    );
};

PlaylistTitle.propTypes = {};

export default withRouter(PlaylistTitle);