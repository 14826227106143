import React from 'react';
import "./Logo.css";
import { withRouter } from 'react-router-dom';

const Logo = (props) => {
    function showPlaylistNames() {
        props.history.push('/collection/playlists/');
    }
    return (
        <div className="logo pointer" onClick={showPlaylistNames} />
    );
};

Logo.propTypes = {};

export default withRouter(Logo);